<template>
    <div class="modal-dialog delete-question-wrap">
        <div class="modal-content">
            <div class="modal-header delete-question-header">
                <h3 class="block-title title_create_question">
                    Удаление вопроса
                </h3>
                <div class="block-options close-question-form-wrap">
                    <button
                        type="button"
                        class="btn-block-option close-question-form close-modal"
                        @click="$modal.hide('question-remove-modal')"
                    >
                        ×
                    </button>
                </div>
            </div>
            <div class="modal-body delete-question-body">
                <div class="question-warning">
                    {{ info_text }}<b>{{ count_users }}</b
                    >. Если хотите скрыть вопрос только от данной должности —
                    <a href="javascript:void(0)" @click="changePermission"
                        >измените настройки доступа</a
                    >
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-rounded btn-primary-red"
                        @click="onSubmit"
                    >
                        Удалить вопрос
                    </button>
                    <button
                        type="button"
                        class="btn btn-rounded btn-alt-secondary"
                        @click="$modal.hide('question-remove-modal')"
                    >
                        Отменить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session'
    import ModalPermissionUpdate from '../../../permission/ModalPermissionUpdate'

    export default {
        name: 'QuestionRemove',
        components: {},
        props: {
            question: {
                type: Object
            }
        },
        data() {
            return {
                info_text: '',
                count_users: ''
            }
        },
        async created() {
            await this.loadData()
        },
        methods: {
            async loadData() {
                try {
                    let request = session.get(
                        `/api/v1/question/${this.question.id}/count_users/`
                    )
                    let response = await request
                    this.count_users = response.data['text']
                    if (this.count_users) {
                        this.info_text = 'Вопрос будет удален у '
                    } else {
                        this.info_text = 'Вопрос будет удален'
                    }
                } catch (err) {
                    console.error(err)
                }
            },
            changePermission() {
                const ModalPermissionUpdate = () =>
                    import(`../../../permission/ModalPermissionUpdate`)
                this.$modal.show(
                    ModalPermissionUpdate,
                    {
                        title: 'Настройка доступа к вопросу',
                        checkbox: 'Как у родительской вопросу',
                        text:
                            'Выберите отделы, должности или сотрудников, которым необходим доступ к данному вопросу',
                        permissionType: 'question',
                        permissionObjectID: this.question.id
                    },
                    {
                        name: 'ModalPermissionUpdate',
                        adaptive: true,
                        maxWidth: 900,
                        width: '100%',
                        height: 'auto'
                    },
                    {
                        'before-close': this.updateTextPermission
                    }
                )
            },
            updateTextPermission(data) {
                if (data.params) {
                    this.$modal.hide('question-remove-modal')
                    this.$swal({
                        title: 'Отлично!',
                        text: 'Данные изменены',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1400
                    })
                }
            },
            async onSubmit() {
                try {
                    await session.delete(
                        `/api/v1/question/${this.question.id}/`
                    )
                    this.$modal.hide('question-remove-modal', {
                        item: this.question
                    })
                } catch (err) {
                    console.error(err)
                }
            }
        }
    }
</script>

<style scoped>
    .delete-question-body {
        padding: 22px 30px 30px;
    }

    .delete-question-body .modal-footer {
        padding: 35px 0 0;
        justify-content: flex-start;
    }

    .delete-question-body .btn-rounded {
        padding: 0 26px;
        height: 38px;
    }
</style>
